import {
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    EDIT_GET_USER_PROFILE,
    EDIT_GET_USER_PROFILE_SUCCESS,
    EDIT_GET_USER_PROFILE_FAIL,
    GET_USER_PROFILE_DATA_ERROR,
    CLEAR_USER_PROFILE_STATE
} from "./actionTypes";

const initialState = {
    profile: {},
    loading: false,
    formLoading: false,
    success: '',
    error: '',
};

const Profile = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_PROFILE:
            return {
                ...state,
                loading: true
            };
        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.payload,
                loading: false
            }
        case EDIT_GET_USER_PROFILE:
            return {
                ...state,
                formLoading: true
            };
        case EDIT_GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.payload,
                success: 'Profile was successfully edited',
                formLoading: false
            };
        case EDIT_GET_USER_PROFILE_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false
            };

        case GET_USER_PROFILE_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case CLEAR_USER_PROFILE_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                formLoading: false
            };
        default:
            return state;
    }
}

export default Profile;
