export const validateFromServer = errors => {
    const fieldsError = [];
    errors.errors.map(error => {
        fieldsError.push({
            name: error.field,
            errors: [error.message],
        })
    })
    return fieldsError;
}
