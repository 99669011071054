import { call, takeEvery, put } from 'redux-saga/effects';
import {
    GET_USER_PROFILE,
    EDIT_GET_USER_PROFILE
} from "./actionTypes";
import {
    getUserProfileSuccess,
    dataError,
    updateUserProfileSuccess,
    updateUserProfileFail
} from "./actions";

import {getUserProfile, editUserProfile} from '../../library/http/backendHelpers'

function* fetchUserProfile() {
    try {
        const response = yield call(getUserProfile);
        yield put(getUserProfileSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* updateUserProfile({ payload: {user}}) {
    try {
        const response = yield call(editUserProfile, user);
        yield put(updateUserProfileSuccess(response.data));
    }catch (error) {
        yield put(updateUserProfileFail(error))
    }
}

function* profileSaga() {
    yield takeEvery(GET_USER_PROFILE, fetchUserProfile);
    yield takeEvery(EDIT_GET_USER_PROFILE, updateUserProfile);
}

export default profileSaga
