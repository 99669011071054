import {
  ROUTES_GET,
  ROUTES_SUCCESS,
  ROUTES_ERROR
  } from "./actionTypes";

  const initState = {
    arrayRoutes: null,
    success: null,
    error: null,
    loading: false
  };


   const Routes = (state = initState, action) =>  {

    switch (action.type) {
      case ROUTES_GET:
        return {
          ...state,
          loading: true
        };
        
      case ROUTES_SUCCESS:
        return {
          ...state,
          arrayRoutes: action.payload,
          success: "Routes retrived successfully",
          loading: false
        };

      case ROUTES_ERROR:
        return {
          ...state,
          error: action.payload,
          loading: false
        };

      default:
        return state;
    }
  }

  export default Routes;