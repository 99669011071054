export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";

export const EDIT_GET_USER_PROFILE = "EDIT_GET_USER_PROFILE";
export const EDIT_GET_USER_PROFILE_SUCCESS = "EDIT_GET_USER_PROFILE_SUCCESS";
export const EDIT_GET_USER_PROFILE_FAIL = "EDIT_GET_USER_PROFILE_FAIL";


export const GET_USER_PROFILE_DATA_ERROR = "GET_USER_PROFILE_DATA_ERROR";
export const CLEAR_USER_PROFILE_STATE = "CLEAR_USER_PROFILE_STATE";
