import {
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    EDIT_GET_USER_PROFILE,
    EDIT_GET_USER_PROFILE_SUCCESS,
    EDIT_GET_USER_PROFILE_FAIL,
    GET_USER_PROFILE_DATA_ERROR,
    CLEAR_USER_PROFILE_STATE
} from "./actionTypes";

export const getUserProfile = () => ({
    type: GET_USER_PROFILE,
});

export const getUserProfileSuccess = (data) => ({
    type: GET_USER_PROFILE_SUCCESS,
    payload: data
});

export const updateUserProfile = (user) => ({
    type: EDIT_GET_USER_PROFILE,
    payload: {user}
});

export const updateUserProfileSuccess = user => ({
    type: EDIT_GET_USER_PROFILE_SUCCESS,
    payload: user,
});

export const updateUserProfileFail = error => ({
    type: EDIT_GET_USER_PROFILE_FAIL,
    payload: error,
});

export const dataError = error => ({
    type: GET_USER_PROFILE_DATA_ERROR,
    payload: error,
});

export const clearState = (state) => ({
    type: CLEAR_USER_PROFILE_STATE,
    payload: state
});
